import React from 'react';

import loadable from '@loadable/component';
import { RouterLocation } from 'connected-react-router';
import { batch, connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { devConsole } from '../../utils/DevConsole';
import { MiscUtils } from '../../utils/MiscUtils';
import { StringUtils } from '../../utils/StringUtils';
import { ConnectedHelmet } from '../atoms/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { LoginWidgetContainer } from '../atoms/LoginWidgetContainer/LoginWidgetContainer';
import { StructuredDataGame, StructuredDataWebPage, StructuredDataWebSite } from '../atoms/StructuredData';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { ADBLOCK_TRACKER_STATUSES, GameState, LS_COOKIE_CONSTS } from '../models/Enums';
import { IGame } from '../models/Game/Game';
import { GameModel } from '../models/Game/GameModel';
import { GameEvents, GameObservable } from '../models/Game/GameObservable';
import { LocalesPath } from '../models/LocalesPath';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../models/PagesData';
import { UserModel } from '../models/User/UserModel';
import { ABTestContext } from '../services/ABTests/ABTestReact';
import { adBlockDetector } from '../services/AdBlockerService';
import AdsService from '../services/AdsService';
import { Analytics } from '../services/Analytics/Analytics';
import { GameService } from '../services/GameService';
// eslint-disable-next-line import/namespace
import { MetaInfoService } from '../services/MetaInfoService';
import { PwaManifestConfig, PWAService } from '../services/PWAService';
import { newRouteAllowedGames, UrlService } from '../services/UrlService';
import UserService from '../services/UserService';
import { setArenaGameSlug, setArkadiumGameSlug, setGameName } from '../store/ducks/games';
import { setPageType } from '../store/ducks/pages';
import { GemsEffects } from '../store/effects/gems.effects';
import { P404Template } from '../templates/p404/404Template';
import IPageMeta from '../types/pageMeta';

const GameTemplate = loadable(() =>
    MiscUtils.loadableRetry(() => import('../templates/Game/GameTemplate'), { retries: 3 })
);

type GamePageProps = {
    game: IGame;
    games: GameModel[];
    user: UserModel;
    recommendedGames: IGame[];
    dispatch?: any;
    url: string;
    promoRedirect?: string;
    gameState: GameState;
    location: RouterLocation<unknown>;
    currentLang: string;
    urlSearch: string;
    customVersion: string;
    gameAPI: string;
    pageType: PageTypes;
    pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
    adFree: boolean;
    subscription: boolean;
    gameObservable: GameObservable;
};

class GamePageBase extends React.PureComponent<GamePageProps> {
    static contextType = ABTestContext;

    state = {
        crystalNestVariation: '',
    };

    set404Status = () => {
        this.props.dispatch(setPageType(PageTypes.NotFound));
    };

    pauseGame = () => {
        this.props.gameObservable?.dispatch({ type: GameEvents.GAME_PAUSE });
    };

    getPathName = (game: IGame, isCanonicalRel?: boolean, hasFrenchLocale?: boolean): string => {
        const { alias } = game;
        const GAMES_PATH = '/games/';
        const JIGSAW_PUZZLES_PATH = hasFrenchLocale ? '/jeux-gratuits/puzzles/' : '/free-online-games/jigsaw-puzzles/';

        if (isCanonicalRel) {
            return newRouteAllowedGames.includes(alias)
                ? `${environment.SITE_BASE_URL}${JIGSAW_PUZZLES_PATH}${alias}/`
                : `${environment.SITE_BASE_URL}${GAMES_PATH}${alias}/`;
        }

        return newRouteAllowedGames.includes(alias) ? `${JIGSAW_PUZZLES_PATH}${alias}/` : `${GAMES_PATH}${alias}/`;
    };

    componentDidMount(): void {
        const { game, dispatch, pageType } = this.props;

        if (game) {
            AdsService.setCurrentGame(game);
        }

        if (pageType !== PageTypes.NotFound && game) {
            dispatch(setPageType(PageTypes.Game));
            dispatch(setArkadiumGameSlug(game?.alias));
            dispatch(setArenaGameSlug(game?.slug));
            dispatch(setGameName(game?.name));
        }

        this.adblockTracker();
        PWAService.getUserVisitPWA('', game, false);

        // apps flyer banner logic here
        if (game?.appStore) {
            if ((window as any).AF) {
                (window as any).AF('banners', 'showBanner');
            }
        }

        window.addEventListener('popstate', this.pauseGame);

        if (UserService.isUserLoggedIn()) {
            batch(() => {
                dispatch(GemsEffects.UpdateGemsAmount());
                dispatch(GemsEffects.UpdatePriceSkipPreroll());
            });
        }

        PWAService.pwaOpenHandle(game);
    }

    componentWillUnmount(): void {
        AdsService.setCurrentGame(null);
    }

    adblockTracker() {
        if (adBlockDetector.adBlockerUsed) {
            if (localStorage.getItem(LS_COOKIE_CONSTS.ADBLOCK_TRACKER) !== ADBLOCK_TRACKER_STATUSES.BLOCKED) {
                localStorage.setItem(LS_COOKIE_CONSTS.ADBLOCK_TRACKER, ADBLOCK_TRACKER_STATUSES.BLOCKED);
            }
        } else if (localStorage.getItem(LS_COOKIE_CONSTS.ADBLOCK_TRACKER) === ADBLOCK_TRACKER_STATUSES.BLOCKED) {
            if (localStorage.getItem(LS_COOKIE_CONSTS.ADBLOCK_TRACKER) !== ADBLOCK_TRACKER_STATUSES.EVENT_SENT) {
                Analytics.trackEvent(Analytics.games.adblockDisabled(this.props.game));
                localStorage.setItem(LS_COOKIE_CONSTS.ADBLOCK_TRACKER, ADBLOCK_TRACKER_STATUSES.EVENT_SENT);
            }
        }
    }

    render() {
        const { game, user, customVersion, gameAPI, adFree, subscription } = this.props;

        if (!game || !(game.metaHTMLTitle && game.name)) {
            this.set404Status();
            return <P404Template />;
        }

        devConsole('GAME (id and data): ', game.slug, game);

        const adFreeValue = adFree || subscription;
        // TODO: this condition is for test only of new game url routes, should remove afterwards
        const jigsawPattern = 'free-online-games/jigsaw-puzzles';
        let allowedGame = false;

        if (StringUtils.containsIgnoreCase(this.props.location.pathname, jigsawPattern)) {
            newRouteAllowedGames.forEach((slug) => {
                if (StringUtils.equalIgnoreCase(game.alias, slug)) {
                    allowedGame = true;
                }
            });

            if (!allowedGame) {
                this.set404Status();
                return <P404Template />;
            }
        }

        if (customVersion) {
            game.updateVersion(customVersion);
        }

        if (gameAPI) {
            game.updateGameAPI(gameAPI);
        }

        const pageMeta: IPageMeta = {
            name: game.metaHTMLTitle,
            url: this.props.url,
            description: game.metaHTMLDescription,
            image: game.promoTile280x280,
            imageWidth: 205,
            imageHeight: 205,
            datePublished: '2016-10-14T11:00:26+00:00',
            dateModified: '2021-02-01T14:10:04+00:00',
        };
        const pwaConfig: PwaManifestConfig = {
            name: `Arkadium ${game.name}`,
            shortName: `${game.name}`,
            icon192Url: `${game.pwaIcon192}`,
            icon512Url: `${game.pwaIcon512}`,
            startUrl: `${environment.SITE_BASE_URL}${this.props.location.pathname}`,
        };
        const localesPath: LocalesPath[] = game.hasFrenchLocale
            ? [
                  {
                      lang: 'fr',
                      path: this.getPathName(game, false, game.hasFrenchLocale),
                  },
              ]
            : [];

        return (
            <>
                <ConnectedHelmet
                    meta={[
                        ...MetaInfoService.getGameMetaInfo(game),
                        {
                            name: 'apple-mobile-web-app-title',
                            content: game.name,
                        },
                    ]}
                    link={[
                        ...MetaInfoService.getLinksInfo(this.getPathName(game), localesPath),
                        {
                            rel: 'canonical',
                            href: this.getPathName(game, true),
                        },
                        { rel: 'apple-touch-icon', href: game.pwaIcon192 },
                        {
                            rel: 'apple-touch-icon',
                            sizes: '180x180',
                            href: game.pwaIcon180,
                        },
                        {
                            rel: 'apple-touch-icon',
                            sizes: '167x167',
                            href: game.pwaIcon167,
                        },
                        {
                            rel: 'apple-touch-icon',
                            sizes: '152x152',
                            href: game.pwaIcon152,
                        },
                        {
                            rel: 'apple-touch-icon',
                            sizes: '120x120',
                            href: game.pwaIcon120,
                        },
                        {
                            rel: 'apple-touch-icon',
                            sizes: '76x76',
                            href: game.pwaIcon76,
                        },
                        PWAService.manifestLinkObject({
                            config: pwaConfig,
                            game,
                            isItGamePage: true,
                            currLang: this.props.currentLang,
                        }),
                    ]}
                ></ConnectedHelmet>
                <ErrorBoundary>
                    <LoginWidgetContainer />
                    <GameTemplate
                        recommendedGames={this.props.recommendedGames}
                        game={game}
                        user={user}
                        url={this.props.url}
                        gameState={this.props.gameState}
                        currentLang={this.props.currentLang}
                        adFree={adFreeValue}
                    />
                    <StructuredDataGame game={game} pageUrl={pageMeta.url} />
                    <StructuredDataWebPage pageMeta={pageMeta} />
                    <StructuredDataWebSite />
                </ErrorBoundary>
            </>
        );
    }
}

export default connect((state, props: RouteComponentProps<any>) => ({
    game: GameService.findGameByArkadiumSlug(GameService.gameModelToGame(state.games), props.match.params.game),
    games: state.games,
    recommendedGames: GameService.findGameRecommendedGames(
        GameService.gameModelToGame(state.games),
        props.match.params.game
    ),
    user: state.user,
    pages: PagesData.getPages(state.pages),
    url: UrlService.buildAbsoluteUrl(props),
    currentLang: state.currentLang,
    customVersion: UrlService.getCustomVersionParam(props),
    gameAPI: UrlService.getGameAPIParam(props),
    promoRedirect: UrlService.getPromoParam(props),
    gameState: state.gameState,
    pageType: state.pageType,
    location: state.router.location,
    urlSearch: props.location.search,
    adFree: state.preLoadData.adFree,
    subscription: state.preLoadData.subscription,
    gameObservable: state.gameObservable,
}))(GamePageBase);
